// TechStackBubbles.js
import "./TechStackBubbles.css";
import nodejs from "../../img/Node.js_logo.png";
import react from "../../img/react.png";
import mongodb from "../../img/MongoDB_Logo.png";
import html from "../../img/HTML5_logo_and_wordmark.png";
import css from "../../img/CSS3_logo_and_wordmark.png";
import sql from "../../img/Sql_data_base_with_logo.png";
import c from "../../img/C_Programming_Language.png";
import cplus from "../../img/ISO_C++_Logo.png";
import vue from "../../img/Vue.js_Logo_2.png";
import angular from "../../img/Angular_full_color_logo.png";
import ec2 from "../../img/aws-ec2.png";
import gcp from "../../img/google-cloud.png";
import redux from "../../img/redux-original.png";
import csharp from "../../img/c-sharp.png";
import mui from "../../img/mui.png";
import bootstrap5 from "../../img/bootstrap5.webp";
import tailwindCss from "../../img/7577995.webp";
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";

const techStack = [
  { name: "NODE.JS", src: nodejs },
  { name: "REACT.JS", src: react },
  { name: "MongoDB", src: mongodb },
  { name: "HTML", src: html },
  { name: "CSS", src: css },
  { name: "MySQL", src: sql },
  { name: "C", src: c },
  { name: "C++", src: cplus },
  // { name: "Vue.js", src: vue },
  { name: "Angular.js", src: angular },
  { name: "AWS EC2", src: ec2 },
  { name: "GCP", src: gcp },
  { name: "Redux Toolkit", src: redux },
  { name: "C#", src: csharp },
  { name: "MUI", src: mui },
  { name: "Bootstrap", src: bootstrap5 },
  { name: "Tailwind CSS", src: tailwindCss },
];

const getRandomSpeed = () => ({
  x: (Math.random() - 0.2) * 2, // Speed between -1 and 1
  y: (Math.random() - 0.2) * 2,
});

const TechStackBubbles = () => {
  const [bubbles, setBubbles] = useState([]);

  // Initialize bubble positions and speeds
  useEffect(() => {
    const initialBubbles = techStack.map((tech, index) => ({
      id: index,
      src: tech.src,
      name: tech.name,
      top: Math.random() * 80 + 10, // Random top position
      left: Math.random() * 80 + 10, // Random left position
      speed: getRandomSpeed(),
    }));
    setBubbles(initialBubbles);
  }, []);

  // Animation logic
  const animationRef = useRef();
  const animate = () => {
    setBubbles((prevBubbles) =>
      prevBubbles.map((bubble) => {
        let newTop = bubble.top + bubble.speed.y;
        let newLeft = bubble.left + bubble.speed.x;

        // Bounce off edges
        if (newTop < 0 || newTop > window.innerHeight - 80) {
          bubble.speed.y *= -1; // Reverse vertical speed
          newTop = Math.max(0, Math.min(newTop, window.innerHeight - 80));
        }
        if (newLeft < 0 || newLeft > window.innerWidth - 80) {
          bubble.speed.x *= -1; // Reverse horizontal speed
          newLeft = Math.max(0, Math.min(newLeft, window.innerWidth - 80));
        }

        return { ...bubble, top: newTop, left: newLeft };
      })
    );
    animationRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    animationRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationRef.current); // Cleanup on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bubble-container">
      <Helmet>
        <title>Tech Stack</title>
        <meta
          name="description"
          content="Welcome to the tech stack page of my React app."
        />
        <meta
          name="keywords"
          content="React, SEO, Helmet, gangwar, utkarsh, tech stack, tech, stack, portfolio, javascript, developer, frontend, react, dev, front-end, tech, programmer"
        />
        <link rel="canonical" href="https://www.gangwar.dev/tech-stack" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="gangwardev" />
        <meta name="publisher" content="Utkarsh Gangwar" />
      </Helmet>
      {bubbles.map((bubble) => (
        <div
          key={bubble.id}
          className="bubble"
          style={{
            top: `${bubble.top}px`,
            left: `${bubble.left}px`,
            position: "absolute",
            transition: "none", // Disable CSS transition for smooth animation
          }}
        >
          <img src={bubble.src} alt={bubble.name} />
          <Box component={"p"} sx={{ color: "text.disabled" }}>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {bubble.name}
            </span>
          </Box>
          {/* MUI */}
        </div>
      ))}
    </div>
  );
};

export default TechStackBubbles;
